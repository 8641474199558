
    /* Navbar animation */
    .navbar {
      transform: translateY(-100%);
      opacity: 0;
      animation: slideDown 1s forwards;
    }

    @keyframes slideDown {
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    /* Heartbeat animation */
    @keyframes heartbeat {
      0%, 100% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
    }

    .heartbeat {
      animation: heartbeat 1.5s infinite;
    }
    